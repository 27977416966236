<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-07-14 18:19:40
 * @LastEditTime: 2022-07-28 15:46:57
 * @LastEditors: cyy
 * @Description: 定向发放详情
 * @FilePath: \teacherdingding.com\src\views\MarketingCenter\coupon\directional.vue
-->
<template>
  <div class="directional">
    <pagination2
      :option="{ grant_id }"
      url="/coupon/recordDetails"
      ref="childDialogBox"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
        >
          <el-table-column label="付费商品" v-if="type == 2">
            <template slot-scope="{ row }">
              <div class="course_contain">
                <div class="tu">
                  <img :src="row.cover_img" alt="" />
                </div>
                <div class="right">
                  <div class="itemtitle">{{ row.name }}</div>
                  <div class="msg">
                    <div class="flex-center">
                      <template v-if="row.price && row.good_type != 7">
                        <div class="money">￥{{ row.price }}</div>
                        <div
                          class="cross_money"
                          v-if="
                            (row.crossed_price && row.crossed_price > 0) ||
                              (row.costprice && row.costprice > 0) ||
                              (row.cost_price && row.cost_price > 0)
                          "
                        >
                          ￥
                          {{
                            row.crossed_price || row.costprice || row.cost_price
                          }}
                        </div>
                      </template>
                      <div class="type" v-if="'is_show' in row">
                        <!-- 1展示 2不展示 -->
                        <img
                          src="~@ass/img/1.3.9.4/icon_dg.png"
                          alt=""
                          v-if="row.is_show == 1"
                        />
                        <img
                          src="~@ass/img/1.3.9.4/icon_cw.png"
                          alt=""
                          v-else
                        />

                        <span v-if="row.is_show == 1">展示到网校</span>
                        <span v-else>不展示到网校</span>

                        <!-- 1已上架  2已下架   -->
                        <img
                          v-if="row.status == 1"
                          class="ml20"
                          src="~@ass/img/1.3.9.4/icon_dg.png"
                          alt=""
                        />
                        <img
                          v-else
                          class="ml20"
                          src="~@ass/img/1.3.9.4/icon_cw.png"
                          alt=""
                        />

                        <span v-if="i.status == 1">已启用</span>
                        <span v-else>已停用</span>
                      </div>
                      <div class="course_type">
                        {{ row.good_type | getGoodtext }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="分组名称" v-if="type == 3">
            <template slot-scope="{ row }">
              {{ row.gname }}
            </template>
          </el-table-column>

          <el-table-column label="标签名称" v-if="type == 4">
            <template slot-scope="{ row }">
              {{ row.tag_name }}
            </template>
          </el-table-column>

          <el-table-column label="用户名称" v-if="type == 5">
            <template slot-scope="{ row }">
              <div class="flex-center">
                <div
                  style="width: 24px;height: 24px;border-radius: 12px;"
                  class="mr10"
                >
                  <img
                    style="height:100%;width:100%"
                    :src="row.uphoto"
                    alt=""
                  />
                </div>

                {{ row.uname }}
              </div>
            </template>
          </el-table-column>

          <el-table-column label="付费时间段" v-if="type == 1 || type == 2">
            <template slot-scope="{ row }">
              {{ row.start_time | formatTimeStamp }} --
              {{ row.end_time | formatTimeStamp }}
            </template>
          </el-table-column>

          <el-table-column label="付费商品" v-if="type == 1">
            <template>
              全部商品
            </template>
          </el-table-column>

          <el-table-column label="发放数量(张)">
            <template slot-scope="{ row }">
              {{ row.count }}
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
  </div>
</template>

<script>
export default {
  name: 'directional',

  data() {
    return {}
  },

  computed: {
    type() {
      return this.$route.params.type
    },
    grant_id() {
      return this.$route.params.id
    },
  },

  filters: {
    getGoodtext(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        case 4:
          text = '系列课'
          break
        case 5:
          text = '公开课'
          break
        case 6:
          text = '电子资料'
          break
        case 7:
          text = '会员'
          break
        case 8:
          text = '知识商品'
          break
      }
      return text
    },

    getType(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '付费用户-指定付费时间'
          break

        case 2:
          text = '付费用户-指定付费商品'
          break
        case 3:
          text = '用户群体-指定学员分组'
          break
        case 4:
          text = '用户群体-指定学员标签'
          break
        case 5:
          text = '指定用户'
          break
      }
      return text
    },
  },

  methods: {
    toDetail(row) {
      this.$router.push({
        path: `/coupon/dataeffect/${this.coupon_id}/directional/${row.grant_id}`,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.directional {
  background-color: #fff;
  padding: 20px;
  .course_contain {
    display: flex;
    .type {
      margin-left: 10px;
      align-items: center;
      display: flex;
      font-size: 12px;
      color: #666666;

      img {
        width: 8px;
        height: 7px;
        margin-right: 6px;
      }

      span {
        font-size: 12px;
        color: #666666;
      }
    }
    .tu {
      width: 66px;
      height: 46px;
      background: #d8d8d8;
      margin-right: 14px;
      flex-shrink: 0;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .right {
      flex: 1;
      overflow: hidden;
      .itemtitle {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        color: #333333;
      }
      .msg {
        display: flex;
        align-items: center;
        width: 500px;
        justify-content: space-between;

        .money {
          font-size: 14px;
          color: #ff3535;
        }
        .cross_money {
          font-size: 14px;
          color: #999999;
          margin: 0 30px 0 15px;
          text-decoration: line-through;
        }
        .course_type {
          font-size: 14px;
          color: #333333;
          margin-left: 30px;
        }

        .del {
        }
      }
    }
  }
}
</style>
